#nprogress .bar {
  background: orange;  /* Change color to match your theme */
  height: 3px;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
}

#nprogress .peg {
  display: none;
} 