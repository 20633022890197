.fab-container {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 1000;
  }
  
  .fab-button {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: white;
    color: orange;
    border: none!important;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes pulseRed {
    0% { box-shadow: 0 2px 5px rgba(255,0,0,0.3); }
    50% { box-shadow: 0 2px 15px rgba(255,0,0,0.5); }
    100% { box-shadow: 0 2px 5px rgba(255,0,0,0.3); }
  }
  
  .fab-button.disconnected {
    box-shadow: 0 2px 5px rgba(255,0,0,0.3);
    animation: pulseRed 5s infinite;
  }
  
  .fab-button.connected {
    box-shadow: 0 2px 5px rgba(0,255,0,0.3);
    animation: none;
  }
  
  .fab-button:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 8px rgba(255,165,0,0.4) !important;
    animation: none;
  }
  
  .fab-button.active {
    transform: rotate(45deg);
  }
  
  .fab-menu {
    position: absolute;
    bottom: 70px;
    right: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 8px 0;
    width: 200px;
  }
  
  .fab-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    text-decoration: none;
    color: orange;
    transition: background-color 0.2s;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  
  .fab-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .fab-icon {
    margin-right: 12px;
    font-size: 20px;
  }
  
  .fab-label {
    font-size: 16px;
  }
  .fab-item a, .fab-item a:visited, a.fab-item, a.fab-item:visited {
  color: inherit;
  text-decoration: none;
  }