#bar-chart {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 300px;
  width: 100%;
}
.bar {
flex-grow: 1;
margin: 2px;
background-color: orange;
color: white;
text-align: center;
}
h3 {text-align: center}