@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

body {
  font-family: 'Noto Sans', sans-serif;
  padding: 10px;
  padding-top: 0;
  width: 750px;
  margin: 0 auto;
}

.post,
a:hover,
.disconnect,
button {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='60' viewport='0 0 100 100' style='fill:black;font-size:30px;'><text y='50%'>👆</text></svg>") 16 0, auto !important;
}

.post {
  border: 1px solid black;
  padding: 5px 25px;
  margin-bottom: 20px;
  word-wrap: break-word;
  border-radius: 2px;
  position: relative;
  width: 100%;
  box-sizing: border-box; /* Add this */
}

.post:hover {
  box-shadow: 0px 0px 5px orange;
}

.post .post-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post h2 {
  margin-block-start: 0.4em;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%; /* Add this */
  box-sizing: border-box; /* Add this */
}

.home-link {
  text-decoration: none;
  color: inherit;
}

.search-container {
  display: flex;
  align-items: center;
}

#search {
  margin-right: 5px;
  padding: 0;
}

button,
#search-btn {
  margin-left: 5px;
  background: orange;
  border: 1px solid orange;
  color: white;
  border-radius: 3px;
}

button:disabled {
  background: grey;
  border: 1px solid grey;
}

#navbar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

#navbar li {
  text-align: center;
}

#navbar li a {
  text-decoration: none;
  color: black;
}

#navbar li a:hover {
  color: orange;
}

.content a {
  color: black !important;
}

.post p:first-of-type a:hover {
  color: orange !important;
}

button {
  text-transform: lowercase;
}

button:disabled {
  cursor: default;
}

.home-link {
  color: black !important
}

.home-link:hover {
  color: orange !important;
  text-decoration: none;
}

a:link,
a:visited {
  color: orange;
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: underline;
}

textarea,
input {
  width: 100%;
  padding: 5px;
  margin: 10px 0;
}

input:read-only {
  background-color: #f3f3f3;
}

.submit {
  width: 100%;
  text-align: center;
}

#stats-info {
  text-align: center;
}

#postContainer {
  width: 100%; /* Add this */
  box-sizing: border-box; /* Add this */
}

.no-posts-message {
  text-align: center;
}

.hidden {
  display: none;
}

footer {
  text-transform: lowercase
}

@media screen and (max-width: 785px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  body {
    width: 95%;
  }

  .header .home-link,
  .header .search-container {
    margin-bottom: 10px;
  }
}

button.link {
  background: transparent;
  color: orange;
  border: none;
  padding: 0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modal-content {
  background: #fff;
  padding: 20px;
  width: 600px;
  height: 500px;
  overflow-y: auto;
  border-radius: 10px;
  position: relative;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.tabs button {
  padding: 10px;
  flex-grow: 1;
  background: none;
  border: none;
  outline: none;
  border-bottom: 2px solid #000;
  border-radius: 0;
  color: #000;
  text-transform: uppercase;
}

.tabs button.active {
  border-bottom: 2px solid orange;
  color: orange;
}

.tab-content {
  display: flex;
  flex-flow: row wrap;
}

.list-item {
  padding: 10px;
  margin: 5px 0;
  background: #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='60' viewport='0 0 100 100' style='fill:black;font-size:30px;'><text y='50%'>👆</text></svg>") 16 0, auto !important;
}

.close-button {
  position: absolute;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  background: orange;
  color: white;
  border: none;
  border-radius: 4px;
  bottom: 20px;
}
.NotificationBar {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: rgba(255, 165, 0, .6);
  margin-bottom: 20px;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
}
.NotificationBar a {
  color: white;
  text-decoration: underline;
}

.NotificationBar a:hover {
  color: white;
  opacity: 0.8;
}
.connectbtn {
  margin-right: 0;  /* Ensure no right margin */
  margin-top: 10px;
  
}

.loading-button {
  position: relative;
  overflow: hidden;
}

.loading-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: linear-gradient(90deg, transparent, #ffffff, transparent);
  animation: loading 1s infinite linear;
}

@keyframes loading {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}