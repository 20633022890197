#content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin: 20px;
}

.item {
  border: 1px solid black;
  padding: 10px;
  text-align: center;
}

a.item {
  color: black;
  text-decoration: none;
}

.item:hover {
  box-shadow: 0px 0px 5px orange;
}

#loading {
  text-align: center;
  font-size: 20px;
}

#navbar li.connectbtn {
  position: relative;
}

#navbar li.connectbtn a {
  padding: 6px 10px;
  background: orange;
  color: #fff;
  border-radius: 5px;
}

.disconnect {
  position: absolute;
  background: orange;
  color: #fff;
  border-radius: 5px;
  padding: 6px 10px;
  top: 66px;
  width: calc(100% - 20px);
  font-size: 14px;
}
.changenamebtn {
  top: 30px;
}